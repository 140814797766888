<template>
  <button @click.prevent="this.$router.go(-1)" class="v-back-btn">
    <v-icon width="38" height="8" name="back-icon"></v-icon>
    Назад
  </button>
</template>
<script>
export default {
  name: "v-back-btn",
};
</script>
<style lang="scss" scoped>
.v-back-btn {
  background-color: transparent;
  color: $black;
  font-size: 18px;
  line-height: 30px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Lato', sans-serif;
  svg {
    margin-right: 18px;
  }
}
</style>