<template>
  <label :for="attrs.id" class="requisites__label">
    <div class="requisites__container">
      <div class="requisites__wrapper">
        <input
          type="radio"
          :name="attrs.name"
          :id="attrs.id"
          :value="value"
          @change="$emit('update:modelValue', $event.target.value)"
          :checked="value === modelValue"
          class="requisites__radio"
        />
        {{ data.type == "card" ? "Карта" : `Счет № ${attrs.index + 1}` }} {{ data.name }}
      </div>
      <slot name="append"> </slot>
    </div>
  </label>
</template>
<script>
export default {
  name: "v-requisites-radio",
  props: {
    modelValue: { type: String, default: null },
    value: { type: String, default: null },
    attrs: { type: Object, default: null },
    data: { type: Object, default: null },
  },
};
</script>
<style lang="scss">
.requisites {
  // .requisites__label

  &__label {
    width: 45%;
    position: relative;
    padding: 30px 39px;
    height: 284px;
    cursor: pointer;
    background-color: $white;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 34px;
    &:last-child {
    }
  }

  // .requisites__container

  &__container {
    height: 100%;
    padding-bottom: 30px;
  }

  // .requisites__wrapper

  &__wrapper {
    display: flex;
    align-items: center;
  }

  // .requisites__radio

  &__radio {
    position: relative;
    appearance: none;
    margin-right: 16px;
    &::before {
      content: "";
      background-image: url("~@/assets/img/card_unchecked_icon.svg");
      background-size: 33px 33px;
      transition: all 0.3s;
      display: flex;
      cursor: pointer;
      width: 33px;
      height: 33px;
      overflow: hidden;
    }
    &:checked {
      &::before {
        background-image: url("~@/assets/img/card_checked_icon.svg");
      }
    }
  }
}
</style>