<template>
  <footer class="footer">
    <div class="footer__container">
      <div class="footer__left">
        <router-link class="footer__logo" to="/">
          <strong>Рестарт</strong> <span> Онлайн </span>
        </router-link>
        <p class="footer__copyright">
          © 2022 <span>ООО МКК “Русское кредитное общество”</span> все права
          защищены
        </p>
      </div>
      <div class="footer__right">
        <a class="footer__phone" href="tel:+78001234567"
          >Поддержка: <span>8 (800) 123-45-67</span></a
        >
        <a class="footer__mail" href="mailto:restartonline@info.ru"
          >e-mail: <span>restartonline@info.ru</span></a
        >
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "v-footer",
};
</script>
<style lang="scss" scoped>
.footer {
  padding: 50px 0;
  background-color: $white;
  // .footer__container

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include container;
    max-width: 1390px;
  }

  // .footer__left

  &__left {
  }

  // .footer__right

  &__right {
  }

  // .footer__right && .footer__left

  &__right,
  &__left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  // .footer__logo

  &__logo {
    font-size: 20px;
    strong {
      font-weight: 900;
    }
  }

  // .footer__phone

  &__phone {
  }
  // .footer__mail

  &__mail {
  }

  // .footer__phone && .footer__mail

  &__phone,
  &__mail {
    font-size: 18px;
    cursor: pointer;
    line-height: 30px;
    color: $black;
    span {
      padding-bottom: 2px;
      border-bottom: 1px solid $black;
    }
  }

  // .footer__copyright

  &__copyright {
    line-height: 25px;
    margin-top: 19px;
    color: $gray;
    span {
      padding-bottom: 2px;
      border-bottom: 1px solid $gray;
    }
  }
}
</style>