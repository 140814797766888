<template>
  <div
    class="divider"
    :style="{
      height: `${$attrs.height}px`,
      width: `${
        $attrs.vertical == 'true'
          ? `${$attrs.width !== undefined ? $attrs.width : '1'}px`
          : '100%'
      }`,
      background: `${
        $attrs.background !== undefined ? $attrs.background : '#000'
      }`,
    }"
  ></div>
</template>
<script>
export default {
  name: "v-divider",
  mounted() {},
};
</script>
<style lang="scss" scoped>
.divider {
  width: 100%;
  height: 1px;
}
</style>