<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout";
import DashboardLayout from "@/layouts/DashboardLayout";
import AuthLayout from "@/layouts/AuthLayout";

export default {
  components: {
    DefaultLayout,
    DashboardLayout,
    AuthLayout,
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "home") + "-layout";
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Akzidenz-Grotesk Pro Light Ext", sans-serif;
  overflow-x: hidden;
  // font-family: 'Lato', sans-serif;
}
html,
body,
#app {
  overflow-x: hidden;
}
</style>
