<template>
  <aside class="dashboard-sidebar">
    <router-link class="dashboard-sidebar__link" to="/current_loan"
      >Текущий займ
      <svg
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23 12C23 18.3513 17.8513 23.5 11.5 23.5C5.14873 23.5 0 18.3513 0 12C0 5.64873 5.14873 0.5 11.5 0.5C17.8513 0.5 23 5.64873 23 12ZM12.3598 4.69922H10.3398V10.5192L10.9198 14.9192H11.7998L12.3598 10.4992V4.69922ZM10.3598 18.4992H12.3598V16.4992H10.3598V18.4992Z"
          fill="#FF8266"
        />
      </svg>
    </router-link>
    <router-link class="dashboard-sidebar__link" to="/bids"
      >Заявки
      <svg
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23 12C23 18.3513 17.8513 23.5 11.5 23.5C5.14873 23.5 0 18.3513 0 12C0 5.64873 5.14873 0.5 11.5 0.5C17.8513 0.5 23 5.64873 23 12ZM12.3598 4.69922H10.3398V10.5192L10.9198 14.9192H11.7998L12.3598 10.4992V4.69922ZM10.3598 18.4992H12.3598V16.4992H10.3598V18.4992Z"
          fill="#FF8266"
        />
      </svg>
    </router-link>
    <router-link class="dashboard-sidebar__link" to="/loan_history"
      >История займов
      <svg
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23 12C23 18.3513 17.8513 23.5 11.5 23.5C5.14873 23.5 0 18.3513 0 12C0 5.64873 5.14873 0.5 11.5 0.5C17.8513 0.5 23 5.64873 23 12ZM12.3598 4.69922H10.3398V10.5192L10.9198 14.9192H11.7998L12.3598 10.4992V4.69922ZM10.3598 18.4992H12.3598V16.4992H10.3598V18.4992Z"
          fill="#FF8266"
        />
      </svg>
    </router-link>
    <router-link class="dashboard-sidebar__link" to="/cards_and_accounts"
      >Карты и счета
      <svg
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23 12C23 18.3513 17.8513 23.5 11.5 23.5C5.14873 23.5 0 18.3513 0 12C0 5.64873 5.14873 0.5 11.5 0.5C17.8513 0.5 23 5.64873 23 12ZM12.3598 4.69922H10.3398V10.5192L10.9198 14.9192H11.7998L12.3598 10.4992V4.69922ZM10.3598 18.4992H12.3598V16.4992H10.3598V18.4992Z"
          fill="#FF8266"
        />
      </svg>
    </router-link>
    <router-link class="dashboard-sidebar__link" to="/profile"
      >Профиль
    </router-link>
    <router-link class="dashboard-sidebar__link" to="/documentation"
      >Документы
      <svg
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23 12C23 18.3513 17.8513 23.5 11.5 23.5C5.14873 23.5 0 18.3513 0 12C0 5.64873 5.14873 0.5 11.5 0.5C17.8513 0.5 23 5.64873 23 12ZM12.3598 4.69922H10.3398V10.5192L10.9198 14.9192H11.7998L12.3598 10.4992V4.69922ZM10.3598 18.4992H12.3598V16.4992H10.3598V18.4992Z"
          fill="#FF8266"
        />
      </svg>
    </router-link>
    <router-link class="dashboard-sidebar__link" to="/photos"
      >Фотографии
    </router-link>
  </aside>
</template>
<script>
export default {
  name: "v-dashboard-sidebar",
};
</script>
<style lang="scss">
.dashboard-sidebar {
  max-width: 300px;
  width: 100%;
  padding: 26px 40px;
  background-color: $white;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  margin-right: 69px;
  // .dashboard-sidebar__link

  &__link {
    line-height: 50px;
    font-size: 18px;
    color: $sixthGray;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #b6b6b6;
    &:last-child {
      border-bottom: none;
    }

    svg {
      margin-left: 5px;
    }
  }
  .router-link-active {
    color: #282735;
  }
}
</style>