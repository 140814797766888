<template>
  <label class="custom-input-label" :for="attrs.id">
    <p class="custom-input-label-text">{{ attrs.label }}</p>
    <slot name="under"></slot>

    <div class="custom-input-wrapper">
      <span class="pre-text" v-if="this.$slots.pre_text">
        <slot name="pre_text"></slot>
      </span>
      <input
        :type="attrs.type"
        class="custom-input"
        :value="modelValue"
        :placeholder="attrs.placeholder"
        :style="{
          'max-width': attrs.width + 'px',
          height: attrs.height + 'px',
        }"
        :id="attrs.id"
        @input="$emit('update:modelValue', $event.target.value)"
        :maxlength="attrs.v_maxlength"
      />
      <slot name="inner"></slot>
      <button class="custom-input-append" v-if="this.$slots.append">
        <slot name="append"></slot>
      </button>
    </div>
  </label>
</template>
<script>
export default {
  name: "v-input",
  props: {
    modelValue: { type: String, default: null },
    attrs: { type: Object, default: null },
  },
};
</script>
<style lang="scss">
.pre-text {
  margin-right: 10px;
}
.custom-input-label {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
}
.custom-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.custom-input-label-text {
  font-size: 16px;
  line-height: 25px;
  color: $secondGray;
  margin-bottom: 5px;
  font-family: "AkzidenzGroteskPro-Ext", sans-serif;
}
.custom-input {
  background-color: $white;
  border-radius: 3px;
  border: 1px solid $fifthGray;
  outline: none;
  padding: 11px 15px;
  max-width: 487px;
  width: 100%;
  font-family: "Lato", sans-serif;
}
.custom-input-append {
  margin-left: 34px;
  color: $blue;
  border: none;
  font-size: 16px;
  line-height: 25px;
  background-color: transparent;
  cursor: pointer;
}
</style>