import {
  createRouter,
  createWebHistory
} from 'vue-router'

const routes = [{
    path: '/',
    name: 'HomeView',
    meta: {
      layout: 'default',
      auth: false
    },
    component: () => import( /* webpackChunkName: "HomeView" */ '../views/HomeView.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      layout: 'dashboard',
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "dashboard" */ '../views/DashboardView.vue')
  },
  {
    path: '/auth',
    name: 'auth',
    meta: {
      layout: 'auth',
      auth: false
    },
    // route level code-splitting
    // this generates a separate chunk (auth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "auth" */ '../views/AuthView.vue')
  },
  {
    path: '/whatsapp_auth',
    name: 'whatsapp_auth',
    meta: {
      layout: 'auth',
      auth: false
    },
    // route level code-splitting
    // this generates a separate chunk (whatsapp_auth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "whatsapp_auth" */ '../views/WhatsappAuthView.vue')
  },
  {
    path: '/viber_auth',
    name: 'viber_auth',
    meta: {
      layout: 'auth',
      auth: false
    },
    // route level code-splitting
    // this generates a separate chunk (viber_auth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "viber_auth" */ '../views/ViberAuthView.vue')
  },
  {
    path: '/telegram_auth',
    name: 'telegram_auth',
    meta: {
      layout: 'auth',
      auth: false
    },
    // route level code-splitting
    // this generates a separate chunk (telegram_auth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "telegram_auth" */ '../views/TelegramAuthView.vue')
  },
  {
    path: '/current_loan',
    name: 'current_loan',
    meta: {
      layout: 'dashboard',
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (current_loan.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "current_loan" */ '../views/CurrentLoanView.vue')
  },
  {
    path: '/bids',
    name: 'bids',
    meta: {
      layout: 'dashboard',
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (bids.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "bids" */ '../views/BidsView.vue')
  },
  {
    path: '/loan_history',
    name: 'loan_history',
    meta: {
      layout: 'dashboard',
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (loan_history.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "loan_history" */ '../views/LoanHistoryView.vue')
  },
  {
    path: '/cards_and_accounts',
    name: 'cards_and_accounts',
    meta: {
      layout: 'dashboard',
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (cards_and_accounts.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "cards_and_accounts" */ '../views/CardsAndAccountsView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      layout: 'dashboard',
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (profile.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "profile" */ '../views/ProfileView.vue')
  },
  {
    path: '/documentation',
    name: 'documentation',
    meta: {
      layout: 'dashboard',
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (documentation.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "documentation" */ '../views/DocumentationView.vue')
  },
  {
    path: '/photos',
    name: 'photos',
    meta: {
      layout: 'dashboard',
      auth: true
    },
    // route level code-splitting
    // this generates a separate chunk (photos.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "photos" */ '../views/PhotosView.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    meta: {
      layout: 'default',
      auth: false
    },
    // route level code-splitting
    // this generates a separate chunk (registration.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( /* webpackChunkName: "registration" */ '../views/RegistrationView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('isAuth')
  const requireAuth = to.matched.some(record => record.meta.auth)

  if (requireAuth && loggedIn == null) {
    next('/auth');
  } else {
    next();
  }
})

export default router
