<template>
  <main class="default-layout default">
    <v-header>
      <div class="header__wrapper">
        <router-link to="/auth" class="header__link header__btn cta-btn"
          >Авторизация</router-link
        >
      </div>
    </v-header>

    <router-view />

    <v-footer></v-footer>
  </main>
</template>
<script>
export default {
  name: "default-layout",
};
</script>
<style lang="scss">
.default {
}
</style>