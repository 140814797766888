<template>
  <label :for="attrs.id" class="custom-radio-label">
    <input
      type="radio"
      :name="attrs.name"
      :id="attrs.id"
      :value="value"
      @change="$emit('update:modelValue', $event.target.value)"
      :checked="value === modelValue"
      class="custom-radio"
    />
    <slot name="append"></slot>
  </label>
</template>
<script>
export default {
  name: "v-radio",
  props: {
    modelValue: { type: String, default: null },
    value: { type: String, default: null },
    attrs: { type: Object, default: null },
  },
};
</script>
<style lang="scss">
.custom-radio-label {
  margin-right: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  &:last-child {
    margin-right: 0;
  }
}
.custom-radio {
  margin: 0;
  margin-right: 15px;
  appearance: none;
  position: relative;
  cursor: pointer;
  &::before {
    content: "";
    display: flex;
    border-radius: 50%;
    border: 1px solid $ninthGray;
    min-width: 22px;
    min-height: 22px;
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transition: all 0.3s;
    left: 50%;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: linear-gradient(180deg, #1816ae 0%, #050671 100%);
    transform: translate(-50%, -50%) scale(0);
  }
  &:checked {
    &::after {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}
</style>