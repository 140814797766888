<template>
  <div class="auth-layout">
    <v-header></v-header>

    <router-view />

    <v-footer></v-footer>
  </div>
</template>
<script>
export default {
  name: "auth-layout",
};
</script>
<style lang="scss">
.auth-layout {
}
</style>