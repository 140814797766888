<template>
  <div class="popup" @click="closePopup" v-if="visible">
    <slot name="popup-body"></slot>
  </div>
</template>
<script>
export default {
  name: "v-popup",
  props: {
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    closePopup(e) {
      if (e.target.classList.contains("popup")) {
        this.$emit("closePopup");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}
</style>