<template>
  <label class="v-checkbox__label">
    <input
      type="checkbox"
      :value="value"
      :checked="modelValue.includes(value)"
      @change="(e) => onChange(e.target.value)"
      class="v-checkbox__input"
    />

    <slot />
  </label>
</template>

<script>
export default {
  name: "v-checkbox",
  props: {
    value: { type: String, default: null },
    modelValue: { type: Array, default: () => [] },
  },
  methods: {
    onChange(value) {
      if (this.modelValue.includes(this.value)) {
        this.$emit(
          "update:modelValue",
          this.modelValue.filter((cv) => cv !== value)
        );
      } else {
        this.$emit("update:modelValue", this.modelValue.concat(value));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-checkbox {
  // .v-checkbox__label

  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  // .v-checkbox__input

  &__input {
    margin: 0;
    margin-right: 19px;
    position: relative;
    appearance: none;
    cursor: pointer;
    &::before {
      content: "";
      display: flex;
      min-width: 20px;
      min-height: 20px;
      border-radius: 3px;
      border: 1px solid $ninthGray;
    }
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      transition: all 0.3s;
      background-image: url("~@/assets/icons/checkbox-success-icon.svg");
      background-size: cover;
      width: 12px;
      height: 9px;
      background-repeat: no-repeat;
    }
    &:checked {
      &::after {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
}
</style>