<template>
  <article
    class="tariff__item"
    :style="{
      'background-color': data.selected == true ? data.styles.activeBgColor : data.styles.bgColor,
    }"
    @click="$emit('selectedTariff', data)"
  >
    <div class="tariff__item-head">
      <h3 class="tariff__item-title">
        <span> {{ data.pre_title }} </span>
        <strong>
          {{ data.title }}
        </strong>
      </h3>
      <p class="tariff__item-condition">
        <span v-if="data.conditions.from !== ''">
          от <strong>{{ data.conditions.from }}</strong> ₽
        </span>
        <span v-if="data.conditions.to !== ''">
          до <strong>{{ data.conditions.to }}</strong> ₽
        </span>
      </p>
    </div>
    <v-divider background="rgba(255, 255, 255, 1)"></v-divider>
    <div class="tariff__item-body">
      <strong class="tariff__item-percent"> {{ data.percent }} % </strong>
      <p class="tariff__item-info">
        {{ data.info }}
      </p>
    </div>
  </article>
</template>
<script>
export default {
  name: "v-tariff-item",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss">
</style>