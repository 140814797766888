<template>
  <div class="dashboard-layout dashboard">
    <v-header>
      <div class="header__wrapper">
        <button class="header__link header__btn cta-btn" @click="logout">
          Выйти
        </button>
      </div>
    </v-header>

    <div class="dashboard__container">
      <div class="dashboard__breadcrumbs">
        <router-link to="/">Главная</router-link>
        <svg
          width="15"
          height="8"
          viewBox="0 0 15 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.8536 4.35355C15.0488 4.15829 15.0488 3.84171 14.8536 3.64645L11.6716 0.464466C11.4763 0.269204 11.1597 0.269204 10.9645 0.464466C10.7692 0.659728 10.7692 0.976311 10.9645 1.17157L13.7929 4L10.9645 6.82843C10.7692 7.02369 10.7692 7.34027 10.9645 7.53553C11.1597 7.7308 11.4763 7.7308 11.6716 7.53553L14.8536 4.35355ZM0 4.5H14.5V3.5H0V4.5Z"
            fill="black"
          />
        </svg>
        <router-link to="/dashboard">Личный кабинет</router-link>
      </div>
      <h1 class="dashboard__title">Личный кабинет</h1>

      <div class="dashboard__wrapper">
        <v-dashboard-sidebar></v-dashboard-sidebar>

        <router-view></router-view>
      </div>
    </div>
    <v-footer></v-footer>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import vDashboardSidebar from "@/components/v-dashboard-sidebar";

export default {
  name: "dashboard",
  components: {
    vDashboardSidebar,
  },
  methods: {
    ...mapActions(["test"]),
    logout() {
      localStorage.removeItem("isAuth");
      this.$router.push("/");
    },
    setTokenToState() {
      let token = localStorage.getItem("token");
      this.$store.state.token = token;
      this.$store.state.authorized = true;
    },
  },
  data() {
    return {
      authorized: "",
    };
  },
  computed: {
    ...mapGetters(["AUTHORIZED"]),
  },
  mounted() {},
  created() {
    this.setTokenToState();
  },
};
</script>
<style lang="scss">
.dashboard-layout {
  background: #f6f6f6;
}
.dashboard {
  // .dashboard__container

  &__container {
    @include container;
    margin-bottom: 60px;
  }

  // .dashboard__breadcrumbs

  &__breadcrumbs {
    a {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }

  // .dashboard__title

  &__title {
    font-size: 40px;
    line-height: 50px;
    color: $secondGray;
    margin: 30px 0 56px 0;
  }

  // .dashboard__wrapper

  &__wrapper {
    display: flex;
    align-items: flex-start;
  }
}
</style>