import { createStore, Store } from "vuex";
import axios from "axios";
import router from "@/router";
// import FormData from 'form-data'
var FormData = require("form-data");

export default createStore({
  state: {
    test: {},
    token: undefined,
    auth_data: {
      login: "",
      password: "",
    },
    authorized: false,
    auth_code: undefined,
    test: false,
    bids: {},
    profile: {},
    loans: {},
    requisites: {},
    documents: {},
    photos: {},
    curr_loan: {},
  },
  getters: {
    TOKEN(state) {
      return state.token;
    },
    AUTH_DATA(state) {
      return state.auth_data;
    },
    AUTH_CODE(state) {
      return state.auth_code;
    },
    BIDS(state) {
      return state.bids;
    },
    PROFILE_INFO(state) {
      return state.profile;
    },
    AUTHORIZED(state) {
      return state.authorized;
    },
    LOANS(state) {
      return state.loans;
    },
    REQUISITES(state) {
      return state.requisites;
    },
    DOCUMENTS(state) {
      return state.documents;
    },
    PHOTOS(state) {
      return state.photos;
    },
    CURR_LOAN(state) {
      return state.curr_loan;
    },
  },
  mutations: {
    SET_TEST_TO_STATE: (state, data) => {
      state.test = data;
    },
    SET_AUTH: (state, res) => {
      if (res.success == true) {
        state.token = res.data.token;
        state.authorized = true;
        localStorage.setItem("isAuth", true);
        localStorage.setItem("token", res.data.token);
        router.push("/current_loan");
      } else if (res.success == false) {
        console.log("error");
      }
    },
    SET_AUTH_DATA: (state, { login, password }) => {
      state.auth_data.login = login;
      state.auth_data.password = password;
    },
    SET_AUTH_MESSENGER: (state, code) => {
      state.auth_code = code;
    },
    SET_BIDS: (state, res) => {
      state.bids = res.data.data;
    },
    SET_PROFILE: (state, res) => {
      state.profile = res.data.data;
    },
    CHECK_SUCCESS_TOKEN: (state, res) => {
      if (res.data.success == false) {
        state.token = "";
        localStorage.removeItem("isAuth");
        localStorage.removeItem("token");
        router.push("/auth");
      }
    },
    SET_LOANS: (state, res) => {
      state.loans = res.data.data;
    },
    SET_REQUISITES: (state, res) => {
      state.requisites = res.data.data;
    },
    SET_DOCUMENTS: (state, res) => {
      state.documents = res.data.data;
    },
    SET_PHOTOS: (state, res) => {
      state.photos = res.data.data;
    },
    SET_CURR_LOAN: (state, res) => {
      state.curr_loan = res.data.data;
    },
  },
  actions: {
    async CHECK_TOKEN({ commit, state }) {
      commit("CHECK_SUCCESS_TOKEN");
    },
    async AUTH({ commit }, data) {
      var formdata = new FormData();
      // let myHeaders = new Headers();
      formdata.append("login", data.login);
      formdata.append("password", data.password);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch("https://api.rucred-dev.ru/v1/auth", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          commit("SET_AUTH", result);
        });
      // .catch((error) => console.log("error", error));
    },
    async MESSENGER_AUTH({ commit }, data) {
      commit("SET_AUTH_DATA", data), commit("SET_AUTH_MESSENGER", "4444");
    },
    async GET_BIDS({ commit, state }) {
      // var myHeaders = new Headers();
      // myHeaders.append("Authorization", `Bearer ${state.token}`);

      // var requestOptions = {
      //   method: "POST",
      //   headers: myHeaders,
      //   redirect: "follow",
      // };

      // fetch("https://api.rucred-dev.ru/v1/account/orders", requestOptions)
      //   .then((response) => response.json())
      //   .then((result) => {
      //     console.log(result);
      //     commit("SET_BIDS", result);
      //   });

      return await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${state.token}`,
        },
        url: `https://api.rucred-dev.ru/v1/account/orders`,
      }).then((res) => {
        commit("CHECK_SUCCESS_TOKEN", res);
        commit("SET_BIDS", res);
      });
    },
    async GET_PROFILE({ commit, state }) {
      // var myHeaders = new Headers();
      // myHeaders.append("Authorization", `Bearer ${state.token}`);

      // var requestOptions = {
      //   method: "POST",
      //   headers: myHeaders,
      //   redirect: "follow",
      // };

      // fetch("https://api.rucred-dev.ru/v1/account/profile", requestOptions)
      //   .then((response) => response.json())
      //   .then((result) => {
      //     commit("SET_PROFILE", result);
      //   });

      return await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${state.token}`,
        },
        url: `https://api.rucred-dev.ru/v1/account/profile`,
      }).then((res) => {
        commit("CHECK_SUCCESS_TOKEN", res);
        commit("SET_PROFILE", res);
      });
    },
    async GET_LOANS({ commit, state }) {
      // var myHeaders = new Headers();
      // myHeaders.append("Authorization", `Bearer ${state.token}`);

      // var requestOptions = {
      //   method: "POST",
      //   headers: myHeaders,
      //   redirect: "follow",
      // };

      // fetch("https://api.rucred-dev.ru/v1/account/loans", requestOptions)
      //   .then((response) => response.json())
      //   .then((result) => {
      //     commit("SET_LOANS", result);
      //   });

      return await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${state.token}`,
        },
        url: `https://api.rucred-dev.ru/v1/account/loans`,
      }).then((res) => {
        commit("CHECK_SUCCESS_TOKEN", res);
        commit("SET_LOANS", res);
      });
    },
    async GET_REQUISITES({ commit, state }) {
      // var myHeaders = new Headers();
      // myHeaders.append("Authorization", `Bearer ${state.token}`);

      // var requestOptions = {
      //   method: "POST",
      //   headers: myHeaders,
      //   redirect: "follow",
      // };

      // fetch("https://api.rucred-dev.ru/v1/account/requesits", requestOptions)
      //   .then((response) => response.json())
      //   .then((result) => {
      //     console.log(result);
      //     commit("SET_REQUISITES", result);
      //   });

      return await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${state.token}`,
        },
        url: `https://api.rucred-dev.ru/v1/account/requesits`,
      }).then((res) => {
        commit("CHECK_SUCCESS_TOKEN", res);
        commit("SET_REQUISITES", res);
      });
    },
    async GET_DOCUMENTS({ commit, state }) {
      // var myHeaders = new Headers();
      // myHeaders.append("Authorization", `Bearer ${state.token}`);

      // var requestOptions = {
      //   method: "POST",
      //   headers: myHeaders,
      //   redirect: "follow",
      // };

      // fetch("https://api.rucred-dev.ru/v1/account/documents", requestOptions)
      //   .then((response) => response.json())
      //   .then((result) => {
      //     console.log(result);
      //     commit("SET_DOCUMENTS", result);
      //   });

      return await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${state.token}`,
        },
        url: `https://api.rucred-dev.ru/v1/account/documents`,
      }).then((res) => {
        commit("CHECK_SUCCESS_TOKEN", res);
        commit("SET_DOCUMENTS", res);
      });
    },
    async GET_PHOTOS({ commit, state }) {
      // var myHeaders = new Headers();
      // myHeaders.append("Authorization", `Bearer ${state.token}`);

      // var requestOptions = {
      //   method: "POST",
      //   headers: myHeaders,
      //   redirect: "follow",
      // };

      // fetch("https://api.rucred-dev.ru/v1/account/photos", requestOptions)
      //   .then((response) => response.json())
      //   .then((res) => {
      //     console.log(res);
      //     commit("SET_PHOTOS", res);
      //   });

      return await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${state.token}`,
        },
        url: `https://api.rucred-dev.ru/v1/account/photos`,
      }).then((res) => {
        commit("CHECK_SUCCESS_TOKEN", res);
        commit("SET_PHOTOS", res);
      });
    },
    async GET_CURR_LOAN({ commit, state }) {
      return await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${state.token}`,
        },
        url: `https://api.rucred-dev.ru/v1/account/loan/current`,
      }).then((data) => {
        commit("CHECK_SUCCESS_TOKEN", data);
        commit("SET_CURR_LOAN", data);
      });
    },
  },
  modules: {},
});
