<template>
  <div class="tariff__list">
    <v-tariff-item
      @selectedTariff="selectedTariff"
      v-for="(item, index) in tariffs"
      :key="index"
      :data="item"
    >
    </v-tariff-item>
  </div>
</template>
<script>
import vTariffItem from "./v-tariff-item.vue";
export default {
  name: "v-tariff-list",
  components: {
    vTariffItem,
  },
  data() {
    return {
      tariffs: [
        {
          selected: true,
          pre_title: "01",
          title: "Рестарт Аванс",
          styles: {
            bgColor: "rgba(0, 100, 156, 0.6)",
            activeBgColor: "rgba(0, 100, 156, 1)",
          },
          conditions: {
            from: "5 000",
            to: "15 000",
          },
          percent: "0,3",
          info: "За каждый день пользования микрозаймом",
        },
        {
          selected: false,
          pre_title: "02",
          title: "Рестарт 5 / 11",
          styles: {
            bgColor: "rgba(104, 30, 178, 0.6)",
            activeBgColor: "rgba(104, 30, 178, 1)",
          },
          conditions: {
            from: "15 000",
            to: "100 000",
          },
          percent: "0,25",
          info: "За каждый день пользования микрозаймом с учётом графика погашения основного долга по микрозайму",
        },
        {
          selected: false,
          pre_title: "03",
          title: "Рестарт Финанс",
          styles: {
            bgColor: "rgba(0, 1, 93, 0.6)",
            activeBgColor: "rgba(0, 1, 93, 1)",
          },
          conditions: {
            from: "",
            to: "100 000",
          },
          percent: "0,25",
          info: "Индивидуальный текст о том, что с пользователем свяжутся после заполнения заявки",
        },
      ],
    };
  },
  methods: {
    selectedTariff(data) {
      this.tariffs.map((item) => (item.selected = false));
      data.selected = true;
      this.$emit("selectedTariff", data.pre_title);
    },
  },
};
</script>
<style lang="scss">
.tariff {
  // .tariff__list

  &__list {
    display: flex;
    justify-content: space-between;
    margin-top: 37px;
    margin-bottom: 21px;
  }

  // .tariff__item

  &__item {
    cursor: pointer;
    color: $white;
    padding: 19px 43px 43px 43px;
    max-width: 456px;
    width: 100%;
    border-radius: 5px;
    transition: 0.4s;
  }

  // .tariff__item-head

  &__item-head {
  }

  // .tariff__item-title

  &__item-title {
    @include h4;
    line-height: 50px;
    font-size: 25px;
    display: flex;
    align-items: flex-end;
    color: $white;
    span {
      font-weight: 400;
      margin-right: 12px;
    }
  }

  // .tariff__item-condition

  &__item-condition {
    font-size: 18px;
    line-height: 39px;
    span {
      font-weight: 400;
    }
  }

  // .tariff__item-divider

  &__item-divider {
    display: none;
  }

  // .tariff__item-body

  &__item-body {
  }

  // .tariff__item-percent

  &__item-percent {
    line-height: 50px;
    font-size: 16px;
  }

  // .tariff__item-info

  &__item-info {
    font-size: 14px;
    line-height: 15px;
  }
}
</style>