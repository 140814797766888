<template>
  <header class="header">
    <div class="header__container">
      <nav class="header__nav">
        <router-link class="header__link header__logo" to="/">
          <img
            src="@/assets/img/logo.png"
            alt="Логотип"
            class="header__logo-image"
          />
          <span class="header__logo-text">
            <strong>Рестарт</strong> Онлайн</span
          >
        </router-link>
      </nav>
      <slot>
      <div class="header__wrapper">
        <router-link to="/auth" class="header__link header__btn cta-btn"
          >Авторизация</router-link
        >
      </div>
      </slot>
    </div>
  </header>
</template>
<script>
export default {
  name: "v-header",
};
</script>
<style lang="scss">
.header {
  padding: 5px 0 30px 0;
  // .header__container

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include container;
  }
  // .header__nav

  &__nav {
  }

  // .header__link

  &__link {
  }

  // .header__btn

  &__btn {
    @include cta-btn;
  }

  // .header__logo

  &__logo {
    display: flex;
    align-items: center;
    position: relative;
  }

  // .header__logo-image

  &__logo-image {
  }

  // .header__logo-text

  &__logo-text {
    // margin-left: 80%;
    margin-left: -35px;
    margin-bottom: 30px;
    font-size: 20px;

    strong {
      font-weight: 900;
    }
  }

  // .header__wrapper

  &__wrapper {
  }
}
</style>