<template>
  <div class="v-chip" :style="{ 'background-color': $attrs.backgroundColor }">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "v-chip",
};
</script>
<style lang="scss">
.v-chip {
  padding: 4px 10px;
  border-radius: 5px;
  color: $white;
}
</style>