<template>
  <div class="v-tooltip">
    <div
      class="v-tooltip__activator"
      :style="{ 'background-color': $attrs.color }"
    >
      <slot name="tooltip-activator"></slot>
    </div>
    <div class="v-tooltip__content">
      <slot name="tooltip-content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "v-tooltip",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss">
.v-tooltip {
  cursor: pointer;
  position: relative;
  // .v-tooltip__activator
  &__activator {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // .v-tooltip__content

  &__content {
    position: absolute;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px 5px 5px 0px;
    padding: 10px;
    color: $secondGray;
    font-size: 11px;
    line-height: 12px;
    font-family: "Lato", sans-serif;
    bottom: 170%;
    max-width: 181px;
    background-color: #fff;
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;
    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 0;
      border-top: 10px solid $white;
      border-right: 10px solid transparent;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    }
  }

  &:hover {
    .v-tooltip__content {
      opacity: 1;
      pointer-events: initial;
    }
  }
}
</style>