<template>
  <div class="v-select">
    <p class="v-select__label">{{ data.label !== "" ? data.label : "" }}</p>

    <!-- <p class="v-select-pre__text" v-if="this.$slots.pre_text">
      <slot name="pre_text"></slot>
    </p> -->

    <div class="v-select__value" @click="openSelect">
      <span> {{ data.selected_option }} </span>
      <svg
        width="11"
        height="6"
        viewBox="0 0 11 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :class="{ 'icon-revert': !data.visible }"
      >
        <path d="M1 5.5L5.5 1L10 5.5" stroke="black" />
      </svg>
    </div>

    <div class="v-select__list" v-show="data.visible">
      <p
        class="v-select__option"
        v-for="(item, index) in data.options"
        :key="index"
        @click="selectOption(item, data), $emit('update:modelValue', item.name)"
      >
        {{ item.name }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "v-select",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    modelValue: { type: String, default: null },
  },
  methods: {
    openSelect(data) {
      if (this.data.visible == true) {
        this.closeSelect();
      } else {
        this.$emit("openSelect", this.data);
      }
    },
    closeSelect() {
      this.$emit("closeSelect", this.data);
    },
    selectOption(option, data) {
      let payload = {
        option: option,
        data: data,
      };
      this.$emit("selectOption", payload);
      this.closeSelect();
    },
  },
};
</script>
<style lang="scss">
.v-select {
  position: relative;
  // .v-select__label

  &__label {
    font-family: "AkzidenzGroteskPro-Ext", sans-serif;
    margin-bottom: 5px;
  }

  // .v-select__value

  &__value {
    font-family: "Lato", sans-serif;
    height: 50px;
    background-color: $white;
    border-radius: 3px;
    border: 1px solid $fifthGray;
    outline: none;
    padding: 11px 15px;
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      transition: 0.3s;
    }
  }

  // .v-select__list

  &__list {
    position: absolute;
    font-family: "Lato", sans-serif;
    top: 100%;
    left: 0;
    margin-top: 2px;
    background-color: $white;
    border-radius: 3px;
    border: 1px solid $fifthGray;
    outline: none;
    padding: 11px 15px;
    width: 100%;
    z-index: 2;
  }

  // .v-select__option

  &__option {
    cursor: pointer;
    transition: 0.3s;
    margin: 8px 0;
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-top: 0;
    }
    &:hover {
      color: #838383;
    }
  }

  // .v-select-pre__text

  &-pre__text {
    white-space: nowrap;
  }
}
.icon-revert {
  transform: rotate(180deg);
}
</style>